import React from "react";
import Filter from "./filter";

function Content() {

    return(
        <React.Fragment>
            <Filter/>
        </React.Fragment>
    )
}

export default Content;